import { useEffect, useState, useRef } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { MESSAGES } from "../../constants/messages";
import { FLOW_STATUS, FLOW_TYPE_ID } from "../../constants/flow";
import { APP_PAGES } from "../../constants/app-pages";
import { CLIENT_FLOWS_STEPS } from "../../constants/tour-guide-steps";
import { APP_CONFIG, DEFAULT_NUM_OF_VERSIONS_TO_DISPLAY } from "../../config/config";

import {
  updateDraft, updatePublished, updateSelectedFlowTypeInformation, updateAvailableFlows,
  updateClientFlows, updateBusinessCode, updateDeploymentEnv
} from "../../redux/actions/client.action";
import { updateVoiceFilePrefixInfo } from "../../redux/actions/voice-file.action";
import { updateChatFlowInfo } from "../../redux/actions/interactive-design.action";

import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import PmivrAppLayout from "../../components/common/layouts/pmivr-app-layout";
import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import InteractiveChatFlows from "./components/interactive-chat-flows/interactive-chat-flows";
import CustomFlow from "./components/custom-flow/custom-flow";
import DocVersionList from "./components/doc-version-list/doc-version-list";
import ChangeHistory from "../../components/change-history/change-history";
import TourGuide from "../../components/common/tour-guide/tour-guide";

import DateUtil from "../../util/date.util";
import AppUtil from "../../util/app.util";

import ClientService from "../../services/client.service";
import FlowService from "../../services/flow.service";
import ShowDnidModal from "./components/show-dnid-modal/show-dnid-modal";
import ConfigService from "../../services/config.service";

/**
 * VIEWS to switch based on conditions
 */
const VIEWS = {
  NEW_FLOW: "NEW_FLOW",
  VERSIONS: "VERSIONS",
  CHAT_FLOWS: "CHAT_FLOWS"
}

/**
 * Displaying the different versions of flow of the client
 * Props in params is of object type.
 * @param {Object} _props Props data from parent component
 */
const ClientFlows = (_props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchQueryParams = new URLSearchParams(location.search);
  // using the open method from the snackbar component
  const snackbarRef = useRef();

  // latest state from redux store
  let { businessCode, selectedFlowTypeInfo, deploymentEnvironment } = useSelector(state => state.client);

  // to store the client's created flows info
  const [clientCreatedFlowTypes, setClientCreatedFlowTypes] = useState([]);
  // to store the all supported flows (like stander ivr, aaivr, custom flow type info)
  const [availableFlowTypes, setAvailableFlowTypes] = useState([]);
  // show create flow buttons for all supported flow types options
  const [showNewFlows, setShowNewFlows] = useState(false);

  // show custom flow name pop up
  const [showCustomNamePopup, setShowCustomNamePopup] = useState(false);
  // to show interactive design pop up on redirection or not
  const [showPopUp, setShowPopUp] = useState(false);
  // to track which flow index is currently opened
  const [activeFlowIndex, setActiveFlowIndex] = useState(0);
  // For setting the chat flow to inactive when clicked
  const [chatFlowId, setChatFlowId] = useState('');
  // the tour steps for the Tour Guide of the current page. 
  const [tourSteps, setTourSteps] = useState([]);
  // current page no for the client flows
  const [page, setPage] = useState(1);
  // selected flow item for re-publish flow
  const [selectedFlowItem, setSelectedFlowItem] = useState([]);
  // filter object for filtering the flow
  const [filterFlowFlag, setFilterFlowFlag] = useState({ flag: '' });
  /**
   * draft : store drafts version info
   * published : store published version info 
   * latestDraft : store latest draft version
   * latestPublished : store latest published version
   * showDraftVersions : To show draft versions or not
   * showPublishedVersions : To show published version or not
   */
  const [versionStatusInfo, setVersionStatusInfo] = useState({
    draft: [], published: [], latestDraft: "", latestPublished: "",
    showDraftVersions: false, showPublishedVersions: false
  });

  // change history dialog properties
  // (showHistoryDialog: flag for popping up the history dialog box, historyInfoTitle: title for the history box that popped up to display 
  // history of changes in diagram, changeHistory: maintains the record for tha changes happening on diagram)
  const [historyDialogProps, setHistoryDialogProps] = useState({
    showHistoryDialog: false, historyInfoTitle: "Document Change History", changeHistory: []
  });

  // selected flows dnid,flow name, flow type id
  const [flowInfo, setFlowInfo] = useState({ dnid: [], flowName: "", flowTypeId: "" });

  /**
   * Loader to show or not
   * snack bar properties (snackbarMsg: message to be displayed, isOpen: flag to open / close the snackbar)
   * set the view of page to display the component according to user selection
   */
  const [uiState, setUiState] = useState({
    viewName: VIEWS.VERSIONS, showDnidDialog: false, showRepublishDialog: false
  });

  useEffect(() => {
    const init = async () => {
      try {

        /**
          * (location?.key === 'default') means direct url is hit.
          * If direct url is hit, then consider the business code from url. 
          * Otherwise, consider it from redux, and if in redux not found, then consider from url.
          * Giving first preference to businessCode in url.
          */
        businessCode = (location?.key === 'default')
          ? location.pathname.split("/").at(-1)
          : (businessCode
            ? businessCode
            : location.pathname.split("/").at(-1));

        /**
         * Checking whether the deployment environments are configured or not.
         * If not configured, then run as normal, no need to verify for the environment on which businessCode is saved.
         * If configured, then giving preferance to the query param and if we found envKey in the query param, then save it in redux,
         * and verify client in that environment.
         * If envKey is not found in query param, then check the redux (saved on home page) and verify the client.
         * if envKey is not found in both queryParam and redux, then show error on the screen.
         * 
         * EnvKey will be set in the query param only when we straight hit the url of the page.
         */
        const isDeploymentEnvironmentExists = await ConfigService.isDeploymentEnvironmentExists();
        const envKeyParam = searchQueryParams.get('envKey');
        if (isDeploymentEnvironmentExists && AppUtil.isValueValid(envKeyParam)) {
          dispatch(updateDeploymentEnv({ deploymentEnvironment: envKeyParam }));
        } else {
          if (isDeploymentEnvironmentExists) {
            if (!AppUtil.isValueValid(deploymentEnvironment)) {
              // opening the snackbar
              snackbarRef.current.open(MESSAGES.ERR.DEPLOYMENT_ENVIRONMENT_NOT_FOUND);
              // giving timeout to remain on the same screen for displaying message
              setTimeout(() => { navigate(APP_PAGES.HOME); }, APP_CONFIG.MESSAGE_TIMEOUT);
              return;
            }
          }
        }
        // verify the client first as we have url client-flows/<businessCode> businessCode can be any so
        // need to verify it if exists then ok else redirect
        const isVerifiedClient = await ClientService.getClientVerification(businessCode);
        // verify the client to check if it exist
        if (!isVerifiedClient?.data) {
          // opening the snackbar
          snackbarRef.current.open(MESSAGES.ERR.CLIENT_DOES_NOT_EXISTS);
          // giving timeout to remain on the same screen for displaying message
          setTimeout(() => { navigate(APP_PAGES.HOME); }, APP_CONFIG.MESSAGE_TIMEOUT);
          return;
        }
        // set flow types system/standard and client specific custom flows
        const { flowTypes, chatFlowIds } = await _setFlowTypes();
        // updating businessCode in redux state (can be the case, businessCode is read from the url)
        dispatch(updateBusinessCode({ businessCode }));
        // handles if url has query params like chatFlowId
        const queryParams = await _handleQueryParams(chatFlowIds);

        // if client has created some flow set first flow as selected flow get the information of that flow
        if (flowTypes.length) {
          // on load info will be set for first flow
          const selectedFlow = flowTypes[0];
          // if it was redirection from other apps that has chatFlow id then no need to load first flow's versions
          if (!queryParams?.chatFlowId) {
            setFlowVersionInfo(businessCode, selectedFlow);
            dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: selectedFlow }));
          } else {
            setUiState({ ...uiState, viewName: VIEWS.CHAT_FLOWS });
          }
          getVoiceFilePrefix(selectedFlow?.wizardConfig);
        } else {
          setVersionStatusInfo({ ...versionStatusInfo, showDraftVersions: false, showPublishedVersions: false });
          // hide the loader of all the processing
        }
      } catch (error) {
        if (snackbarRef?.current) {
          snackbarRef.current.open(MESSAGES.ERR.CLIENT_FLOWS_LOAD);
        }
      }
    }
    init();
  }, []);

  // re-render page every time published and draft changes
  useEffect(() => {
    const clientFlowTourSteps = (versionStatusInfo.draft.length || versionStatusInfo.published.length)
      ? CLIENT_FLOWS_STEPS.EXISTING_FLOW : CLIENT_FLOWS_STEPS.NEW_FLOW;
    setTourSteps(clientFlowTourSteps.steps);
    resetDisplayVersionStates();
    setDisplayVersionInfoVariables();
  }, [versionStatusInfo.published, versionStatusInfo.draft]);

  /**
   * Updating the history dialog properties in state
   * @param {boolean} showHistoryDialog  Flag to pop up change history dialog
   * @param {Array} changeHistory  Array of change history of diagram
   */
  const setChangeHistoryDialogProperties = (showHistoryDialog = false, changeHistory = []) => {
    const historyDialogObj = { ...historyDialogProps };
    historyDialogObj.showHistoryDialog = showHistoryDialog;
    historyDialogObj.changeHistory = changeHistory;
    setHistoryDialogProps(historyDialogObj);
  }

  // Close the history box by setting flag false
  const closeHistoryDialog = () => setChangeHistoryDialogProperties(false, []);

  // Show history box by setting flag true
  const showHistoryDialogBox = (changeHistory) => setChangeHistoryDialogProperties(true, changeHistory);

  // reset the show version info variables to init state
  const resetDisplayVersionStates = () => {
    // reset the initial value of the states
    // latest Published
    // latest published version
    // latest draft version
    setVersionStatusInfo({
      ...versionStatusInfo, latestDraft: "", latestPublished: "", showDraftVersions: false,
      showPublishedVersions: false
    });
  }

  /**
   * Set flow types standard and custom created
   * @returns {Promise<{flowTypes, chatFlowIds}>}
   */
  const _setFlowTypes = async () => {
    // set the supported flows and client's created flows
    const promises = [ClientService.getClientCreatedFlowTypes(businessCode), FlowService.getFlowTypesInfo()];
    const [flowData, availFlowTypes] = await Promise.all(promises);

    // set all the supported flow
    const availFlowTypesInfo = availFlowTypes?.data || [];
    setAvailableFlowTypes(availFlowTypesInfo);
    dispatch(updateAvailableFlows({ flows: availFlowTypesInfo }));

    // set the client's created flows
    const flowTypes = flowData?.data || [];
    let chatFlowIds;
    if (flowTypes.length) {
      chatFlowIds = getChatFlowIds(flowTypes);
    }
    setClientCreatedFlowTypes(flowTypes);
    dispatch(updateClientFlows({ clientFlows: flowTypes }));
    return { flowTypes, chatFlowIds };
  }

  /**
   * Gets chat flow Id's from the clientFlows array
   * @param {Array} clientCreatedFlows 
   * @returns {Array} createdChatFlowIds
   */
  const getChatFlowIds = (clientCreatedFlows) => {
    // filtering the custom flows which are created from interactive chat flows as only those flows will be having chatFlowId
    const createdChatFlowIds = clientCreatedFlows.filter((clientCreatedFlow) => clientCreatedFlow?.chatFlowId)
      .map(clientCreatedFlow => clientCreatedFlow?.chatFlowId);

    return createdChatFlowIds;
  }

  // set display version info variables to display the flow versions info
  const setDisplayVersionInfoVariables = () => {
    setVersionStatusInfo({
      ...versionStatusInfo,
      latestDraft: (versionStatusInfo?.draft?.length) ? versionStatusInfo.draft[0] : null,
      latestPublished: (versionStatusInfo.published?.length) ? versionStatusInfo.published[0] : null,
      showDraftVersions: (versionStatusInfo.draft.length > 1),
      showPublishedVersions: (versionStatusInfo.published.length > 1)
    });
  }

  /**
   * Gets the chatFlowId from the query of the url
   * @param {Array} chatFlowIds 
   * @returns {Promise<string>} chatFlowId
   */
  const _handleQueryParams = async (chatFlowIds) => {
    const searchParams = new URLSearchParams(location.search);
    const chatFlowId = searchParams.get('chatFlowId') || "";
    const appCode = ClientService.getAppCode();

    // If chatFlowId is there send it in the flow version info
    // Also check if the appCode is biller direct
    if (chatFlowId && appCode === "biller-direct") {
      const basicFlowInfo = {};
      basicFlowInfo.businessCode = businessCode;
      basicFlowInfo.flowType = "draft";
      // For setting in the local storage
      FlowService.setBasicFlowInfo(basicFlowInfo);

      // For the flows if it includes the chatFlowId in the query
      if (chatFlowIds.includes(chatFlowId)) {
        const flowData = {
          flowName: '', flowTypeId: FLOW_TYPE_ID.INTERACTIVE_DESIGN_FLOW
        };
        alert(MESSAGES.FLOW_ALREADY_PRESENT);
        await setFlowVersionInfo(businessCode, flowData, 0, chatFlowId);
      } else {
        dispatch(updateChatFlowInfo({ chatFlowId: chatFlowId }));
        setShowPopUp(true);
        dispatch(updateBusinessCode({ businessCode }));
        setUiState({ ...uiState, viewName: VIEWS.CHAT_FLOWS });
        return { chatFlowId };
      }
    }
  }

  /**
   * Sets the flow version information for the specified flow.
   * Fetches flow information for any flow and updates the draft and published versions in redux and states,
   * Cases where this function will be called:
   * case 1: when any flow from left panel is clicked (flow changed)
   * case 2: when any flow from left panel is clicked repeatedly
   * case 3: when list of draft/published versions is scrolled to bottom, 
   * and appending the remaining flows at the end of default number of versions
   * because at a time only specified number of versions are displayed
   * case 4: for interactive chat flows
   * @param {string} businessCode businessCode name
   * @param {{flowTypeId: string, flowName: string, dnid: [{lext,filledVars}]}} flowTypeInfo 
   * @param {string} flowIndex selected flow index
   * @param {string} chatFlowId chatFlow Id of interactive design flow
   * @param {number} pageNo current page number
   * @param {number} isPagination checks if scroll has happened or not, true: scrolled to bottom, false: either flow type has changed, or clicked on same index
   */
  const setFlowVersionInfo = async (businessCode, flowTypeInfo, flowIndex = 0, chatFlowId = '', pageNo = 1, isPagination = false) => {
    try {
      // get the flow information
      const filters = {
        flowName: flowTypeInfo.flowName, flowTypeId: flowTypeInfo.flowTypeId, chatFlowId, pageNo, count: DEFAULT_NUM_OF_VERSIONS_TO_DISPLAY
      }
      const flows = await ClientService.getFlowInfo(businessCode, filters);
      const { draft, published } = flows;
      // append the current draft and published with paginated result
      const publishedArray = (flowTypeInfo.flowName === selectedFlowTypeInfo.flowName) && (isPagination) ? [...versionStatusInfo.published, ...published] : published;
      const draftArray = (flowTypeInfo.flowName === selectedFlowTypeInfo.flowName)  && (isPagination) ? [...versionStatusInfo.draft, ...draft] : draft;
      if (draft.length || published.length) {
        setPage(pageNo + 1);
      }
      // update flow related values in store
      dispatch(updatePublished({ published: publishedArray }));
      dispatch(updateDraft({ draft: draftArray }));
      // if chatFlowId is there then set it active when clicked on the flow
      if (chatFlowId) {
        setChatFlowId(chatFlowId);
        flowIndex = null;
      } else {
        // Set the use state chatFlowId to null so that the flow does not remains active
        setChatFlowId('');
      }
      // set the flow index
      setActiveFlowIndex(flowIndex);
      // set draft and published state 
      setVersionStatusInfo({ ...versionStatusInfo, draft: draftArray, published: publishedArray });
      setFlowInfo({
        dnid: flowTypeInfo?.dnid, flowName: flowTypeInfo?.flowName,
        flowTypeId: flowTypeInfo?.flowTypeId
      })
      setShowNewFlows(false);
      dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: flowTypeInfo }));
      getVoiceFilePrefix(flowTypeInfo?.wizardConfig);
      setUiState({ ...uiState, viewName: VIEWS.VERSIONS });
    } catch (error) {
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.CLIENT_FLOWS_LOAD);
      }
    }
  }

  /**
   * Get the voice file suffix to append in base path and set it to redux
   * @param {{Array}} wizardConfig Array of questions
   */
  const getVoiceFilePrefix = (wizardConfig) => {
    if (wizardConfig?.length) {
      const voicePathData = wizardConfig[0]?.children;
      const voiceFilePrefixInfo = {};
      voicePathData.forEach((item) => {
        voiceFilePrefixInfo[item.key] = item.value;
      });
      dispatch(updateVoiceFilePrefixInfo({ voiceFilePrefixObj: voiceFilePrefixInfo }));
    }
  }

  /**
   * Navigate to wizard page and set selected flow type information
   * @param {string} flowTypeId 
   * @param {string} flowName 
   */
  const navigateToWizard = async (flowTypeId, flowName) => {
    dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: { flowTypeId, flowName } }));
    navigate(`${APP_PAGES.WIZARD}/${businessCode}`);
  }

  /**
   * Handle new flow button to set he states
   */
  const handleNewFlow = () => {
    dispatch(updateChatFlowInfo({ chatFlowId: "" }));
    setShowNewFlows(true);
    setActiveFlowIndex(null);
    setUiState({ ...uiState, viewName: VIEWS.NEW_FLOW });
    // HACK : to avoid open popup in case of clicking interactive design
    setShowPopUp(false);
  }

  /**
   * Handle new flow button to set he states
   */
  const handleInteractiveDesginFlow = () => {
    setUiState({ ...uiState, viewName: VIEWS.CHAT_FLOWS });
  }

  /**
   * Handles the scroll event to load more versions if the user has scrolled to the bottom.
   * @param {Event} e - The scroll event.
   * @param {string} flowType - The type of flow version.
   */
  const onScroll = async (e, flowType) => {
    // Threshold for detecting when user is near the bottom of the scrollable area.
    const threshold = 5;
    // Check if the user has scrolled near the bottom.
    const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= threshold;
    const flowVersionArray = versionStatusInfo[flowType] ?? [];
    // fetch current selected flow types
    const selectedTypes = clientCreatedFlowTypes[activeFlowIndex];
    if (flowVersionArray.length >= DEFAULT_NUM_OF_VERSIONS_TO_DISPLAY) {
      // Check if user has scrolled to bottom and on same flow index 
      if (bottom && selectedTypes.flowName === selectedFlowTypeInfo.flowName) {
        await setFlowVersionInfo(businessCode, selectedFlowTypeInfo, activeFlowIndex, '', page, true);
      }
    }
  }

  /**
   * View content is set based on view name and then ui is render
   */
  const setViewContent = (viewName) => {
    let viewContent;
    switch (viewName) {
      case VIEWS.NEW_FLOW:
        viewContent = <div className="mt-4 row" id="flow-types">
          {/* flow types list */}
          <div className="pmivr-title" style={{ marginBottom: "10px", marginTop: "15px" }}>Flow Types</div>
          {
            availableFlowTypes.map((flowType, index) => {
              // if client already created a the flow then disable that flow type button
              let disabled = !!clientCreatedFlowTypes.find((flow) => {
                return flow.flowTypeId === flowType.flowTypeId
                  && ![FLOW_TYPE_ID.CUSTOM_FLOW_ID, FLOW_TYPE_ID.INTERACTIVE_DESIGN_FLOW].includes(flow.flowTypeId);
              })

              return (
                <div className="col-md-3" key={index}>
                  <div id={flowType.flowTypeId} disabled={disabled}
                    className={`pmivr-card card flow-type-card mb-3 p-3 ${disabled ? 'is-disabled'
                      : `flow-type-card-${flowType?.borderColor}`
                      }`}
                    onClick={(_event) => {
                      if (flowType.flowTypeId === FLOW_TYPE_ID.INTERACTIVE_DESIGN_FLOW) {
                        handleInteractiveDesginFlow();
                      } else if (flowType.flowTypeId !== FLOW_TYPE_ID.CUSTOM_FLOW_ID) {
                        navigateToWizard(flowType.flowTypeId, flowType.name);
                      } else {
                        setShowCustomNamePopup(true);
                      }
                    }}>
                    <div className="my-auto mx-auto text-center">
                      <div className="icon-container">
                        <i className="bi bi-plus"></i>
                      </div>
                      <h6 className="pmivr-title pt-2 pb-3 text-center" disabled={disabled}>
                        {flowType?.name}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>;
        break;
      case VIEWS.VERSIONS:
        if (versionStatusInfo.latestDraft || versionStatusInfo.latestPublished) {
          viewContent =
            <DocVersionList versionStatusInfo={versionStatusInfo} flowInfo={flowInfo} filterFlowFlag={filterFlowFlag} onFlowFilterChange={onFlowFilterChange}
              showHistoryDialogBox={showHistoryDialogBox} onScroll={onScroll} onToggleRepublishDialog={onToggleRepublishDialog} onFlagStatusChange={onFlagStatusChange} />
        } else {
          viewContent = <div className="row">
            <div className="col-md-8 mt-4 ">
              <div className="no-flow-msg">
                No flow configured for this client
              </div>
              <button className="pmivr-btn-app mb-4 btn-lg btn-add-new create-flow-btn" onClick={() => {
                handleNewFlow();
              }} id="create-new-flow">  + Create Flow </button>
            </div>
          </div>;
        }
        break;
      case VIEWS.CHAT_FLOWS:
        viewContent = <InteractiveChatFlows businessCode={businessCode} showPopUp={showPopUp} setFlowVersionInfo={setFlowVersionInfo}
          handleNewFlow={handleNewFlow} />
        break;
      default:
        // in default case the view content will be empty
        viewContent = <></>;
        break;
    }

    return viewContent;
  }

  /**
   * handle open or close the view dnid dialog only when flow is selected and not on when we click on create new flow
   */
  const handleDnidDialog = () => {
    if (!showNewFlows) {
      setUiState({ ...uiState, showDnidDialog: !uiState?.showDnidDialog });
    }
  }

  /**
   * Handles the display of the republish dialog.
   * @param {Event} e - The event object.
   * @param {Object} selectedFlowItem - The flow item that has been selected for republishing.
   * 
  */
  const onToggleRepublishDialog = (e, selectedFlowItem) => {
    if (selectedFlowItem) {
      setSelectedFlowItem(selectedFlowItem);
    }
    setUiState({ ...uiState, showRepublishDialog: !uiState?.showRepublishDialog })
  }

  /**
   * Handles the republishing of a flow.
   */
  const republishFlow = async () => {
    const filters = {
      flowName: selectedFlowTypeInfo.flowName, flowTypeId: selectedFlowTypeInfo.flowTypeId, chatFlowId: selectedFlowTypeInfo?.chatFlowId
    };
    await FlowService.republishFlow(selectedFlowItem);
    const flows = await ClientService.getFlowInfo(businessCode, filters);
    const { draft, published } = flows;
    // Set the current page to 2, as page 1 has already been fetched. 
    // This update will ensure that the next page to be fetched is page 2.
    setPage(2);
    setUiState({ ...uiState, showRepublishDialog: !uiState?.showRepublishDialog });
    // update flow related values in store
    dispatch(updatePublished({ published }));
    dispatch(updateDraft({ draft }));
    setVersionStatusInfo({
      ...versionStatusInfo,
      draft, published,
      latestDraft: (draft?.length) ? draft[0] : null,
      latestPublished: (published?.length) ? published[0] : null,
      showDraftVersions: (draft.length > 1),
      showPublishedVersions: (published.length > 1)
    });
  }

  /**
   * Handles the update of a flag status for a given document by updating the document's flag 
   * status in the backend and synchronizes the frontend state.
   * @param {Object} doc flow item document
   * @param {string} flag new flag status to be set for the document.
   * @param {string} flowType flow type of the selected flow item
   * 
  */
  const onFlagStatusChange = async (doc, flag, flowType) => {
    await FlowService.updateFlag(doc?.versionId, flag);
    const tmpVersionList = [...versionStatusInfo[flowType]];
    const index = tmpVersionList.findIndex(obj => obj._id === doc._id);
    //get the flow to update
    const flowToUpdate = { ...tmpVersionList[index] };
    flowToUpdate["flag"] = flag;
    if (index !== -1) {
      tmpVersionList[index] = flowToUpdate;
    }
    // update flow related values as per flow type
    if (flowType === FLOW_STATUS.DRAFT) {
      dispatch(updateDraft({ draft: tmpVersionList }));
      setVersionStatusInfo({
        ...versionStatusInfo,
        draft: tmpVersionList,
      });
    }
    else if (flowType === FLOW_STATUS.PUBLISHED) {
      dispatch(updatePublished({ published: tmpVersionList }));
      setVersionStatusInfo({
        ...versionStatusInfo,
        published: tmpVersionList,
      });
    }
  }

  /**
   * Handles the change in flag status and filter the flow
   * @param {string} flag the selected flow flag
   */
  const onFlowFilterChange = async (flag) => {
    const filters = { flowName: selectedFlowTypeInfo.flowName, flowTypeId: selectedFlowTypeInfo.flowTypeId, chatFlowId: selectedFlowTypeInfo?.chatFlowId };
    const flows = await ClientService.getFlowInfo(businessCode, filters);
    const { draft, published } = flows;
    // update flow related values in store
    dispatch(updatePublished({ published }));
    dispatch(updateDraft({ draft }));
    // set draft and published state 
    setVersionStatusInfo({ ...versionStatusInfo, draft, published });
    // working or failed flag status
    if (flag) {
      // filter working status flag flow version
      const tmpFlowVersion = [...published];
      const filterFlowVersionList = tmpFlowVersion.filter((item) => item.flag === flag);
      // update flow related values
      dispatch(updatePublished({ published: filterFlowVersionList }));
      setVersionStatusInfo({
        ...versionStatusInfo,
        published: filterFlowVersionList,
      });
    }
    // Set the current page to 2, as page 1 has already been fetched. 
    // This update will ensure that the next page to be fetched is page 2.
    setPage(2);
    setFilterFlowFlag((prevFilterObj) => {
      const newFilterObj = { ...prevFilterObj };
      newFilterObj.flag = flag;
      return newFilterObj;
    });
  }

  return (
    <PmivrAppLayout showLeftBar={false} showFooter={true}>
      {tourSteps?.length && (
        <TourGuide steps={tourSteps} />
      )}

      <PmivrSnackbar ref={snackbarRef} />

      {/* dialog box for displaying dnid's configured  */}
      <PmivrDialog showDialog={uiState.showDnidDialog} closeDialog={() => handleDnidDialog()}
        title={`DNID's Configured For Flow ${flowInfo?.flowName}`} cssClass={"dnid-display-modal"}
        message={<ShowDnidModal flowInfo={flowInfo} />}
        footer={<></>}
      />

      {/* dialog box for displaying the history changes  */}
      <PmivrDialog showDialog={historyDialogProps.showHistoryDialog} closeDialog={() => closeHistoryDialog()}
        title={historyDialogProps.historyInfoTitle}
        message={<ChangeHistory history={historyDialogProps.changeHistory}></ChangeHistory>} />

      {/* dialog box for custom flow name */}
      <PmivrDialog showDialog={showCustomNamePopup} closeDialog={() => setShowCustomNamePopup(false)}
        title={MESSAGES.ENTER_CUSTOM_FLOW_NAME}
        message={<CustomFlow businessCode={businessCode} closeAction={() => setShowCustomNamePopup(false)} />}
        footer={<></>} />

      <PmivrDialog showDialog={uiState.showRepublishDialog} closeDialog={onToggleRepublishDialog}
        title={`Republish Selected Flow`}
        message={
          <>
            <div>
              Do you want to Re-Publish the selected flow version?
            </div>
            <div className="text-end mt-4">
              <div className="d-inline-block mx-2">
                <button className="pmivr-btn-secondary p-2" type="button" onClick={onToggleRepublishDialog}>Cancel</button>
              </div>
              <div className="d-inline-block mx-2">
                <button className="pmivr-btn-app p-2" type="submit" onClick={republishFlow}>Confirm</button>
              </div>
            </div>
          </>
        }
        footer={<></>}
      />

      <div className="pmivr-container left-bar-hidden">
        <div className="row border-bottom  pb-3 pt-3 ">
          <div className="col-lg-6">
            <div className="px-3 pmivr-breadcrumb-list">
              {deploymentEnvironment ? `${deploymentEnvironment} : ` : ``}<Link to={`/home`}>Home</Link> / {businessCode}
            </div>
          </div>
        </div>
        <div className="pmivr-client-flow">
          <div className="row">
            <div className="col-md-3">

              {/* left bar */}
              <div className="left-bar p-3">
                <button className="pmivr-btn-app mb-4 btn-lg btn-add-new" onClick={() => {
                  handleNewFlow();
                }}>  <i class="bi bi-plus-circle"></i> <span className="pl-3">Create Flow</span> </button>
                <p className="latest-flow-heading">Latest Flows</p>
                {clientCreatedFlowTypes.map((flowType, index) => {
                  return (
                    <div
                      key={index}
                      id={flowType.flowTypeId}
                      className={`${(activeFlowIndex === index) || (chatFlowId === flowType?.chatFlowId) ?
                        "pmivr-card card card-efect active-flow mb-3 p-3"
                        : `pmivr-card card card-efect-${flowType?.borderColor} mb-3 p-3 left-border`
                        } left-border-${flowType?.borderColor} `}
                      onClick={(e) => {
                        setFilterFlowFlag({ flag: '' });
                        setFlowVersionInfo(businessCode, flowType, index, '', 1);
                      }}>
                      <h6 className="pmivr-title pt-2 pb-3 text-center">{flowType.flowName}</h6>
                      <i className={`${(activeFlowIndex === index) || (chatFlowId === flowType?.chatFlowId) ? `bi bi-dot active-icon` : `d-none`}`}></i>
                      <div className="row border-top mt-2 pt-3">
                        <div className="col-sm-6 text-left pmivr-label ">Last Modified</div>
                        <div className="col-sm-6 text-end">{DateUtil.toDateString(flowType.updatedOn)}</div>
                      </div>
                    </div>
                  );
                })}
              </div>

            </div>

            {/* businessCode info and flow listing */}
            <div className="col-md-9">
              <div className="business-code-version p-3">
                {/* basic info */}

                <div className="row info-header" id="info-header">
                  <div className={"col-md-4"}>
                    <div className={`card pmivr-card p-4`}>
                      <ul className={"card-list-items"}>
                        <span className="text">BUSINESS CODE</span> <br />
                        <li className="pmivr-text-primary text-bold" >{businessCode}</li>
                      </ul>
                    </div>
                  </div>
                  <div className={`col-md-4 ${showNewFlows ? "disable-view-dnid" : "enable-view-dnid"}`}
                    onClick={() => handleDnidDialog()}>
                    <div className={`card pmivr-card p-4`}>
                      <ul className={"card-list-items"}>
                        <span className="text">VIEW DNID's</span>
                        <br />
                        <li></li>
                      </ul>
                    </div>
                  </div>
                  {clientCreatedFlowTypes &&
                    <div className={"col-md-4"}>
                      <div className={`card pmivr-card text-center p-4`}>
                        <ul className={"card-list-items"}>
                          <span className="text">Flow Name</span> <br />
                          <li className="pmivr-text-primary card-item-text text-bold" >{!showNewFlows ? flowInfo.flowName : ""}</li>
                        </ul>
                      </div>
                    </div>}
                </div>

                {/* based on selected view ui will be visible */}
                {setViewContent(uiState.viewName)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PmivrAppLayout>
  );
}

export default ClientFlows;