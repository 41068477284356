import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";

import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";

import UserService from '../../../services/user.service';

import { PmivrDialog } from '../../../components/common/dialog/pmivr-dialog';
import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger";

/**
 * Dialog box to upload xml file done in flow (draft or publish doc)
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render dialog
 */
const UploadFileDialog = forwardRef((props, ref) => {
    const { selectedFlowType, uploadBaseFlow } = props;

    // using the open method from the snackbar component
    const snackbarRef = useRef();

    // variable to show and hide the dialog box
    const [dialogProps, setDialogProps] = useState({ showDialog: false });

    useEffect(() => {
        const init = async () => {
            try {
            } catch (err) {
                // opening the snackbar
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
        init();
    }, []);

    // exposing functions to parent component
    useImperativeHandle(ref, () => ({
        open() {
            setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: true }));
        },
        close: closeDialog
    }));

    // validating the formik fields
    const validate = Yup.object({
        comments: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        file: Yup.mixed().required(MESSAGES.ERR.XML_FILE_REQUIRED)
            .test('is-valid-type', MESSAGES.ERR.INVALID_XML_FILE, (value) => {
                if (value) {
                    if (['text/xml'].includes(value?.type) && value?.name?.endsWith(".xml")) {
                        return true;
                    } else {
                        return false;
                    }
                }
            })
    });

    // function to close the upload file dialog box
    const closeDialog = () => {
        setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: false }));
    }

    return (
        <PmivrDialog showDialog={dialogProps.showDialog}
            closeDialog={closeDialog}
            title={selectedFlowType?.name}
            message={
                <Formik initialValues={{ file: null, flowTypeId: null, name: null, comments: '' }}
                    validationSchema={validate} onSubmit={uploadBaseFlow}>
                    {({ setFieldValue, values, errors, setErrors }) => (
                        <Form>
                            <div className="pmivr-title pt-2 text-start row" >
                                <label className="pmivr-label pb-2">Mention the changes that has been made in this version</label>
                                {UserService.hasPermission() && (
                                    <div className="form-group template-textarea mb-2">
                                        <textarea id={`comments-${selectedFlowType.flowTypeId}`} name="comments"
                                            className="form-group doc-comments pmivr-input" rows="3"
                                            value={values.comments}
                                            placeholder="Enter the changes"
                                            onChange={(event) => {
                                                setFieldValue("comments", event.target.value);
                                                // resetting the event target value as some browsers 
                                                // did not clear the event target for input file
                                                event.target.value = '';
                                            }}
                                        />
                                        <ErrorMessage name="comments" component="div"
                                            className="field-error error pt-2" />
                                    </div>
                                )}
                            </div>

                            {UserService.hasPermission() && (
                                <div className="text-start pt-3">
                                    <div className="pmivr-drop-audio-file template-drop-audio-file">
                                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.UPLOAD_VOICE_FILE}>
                                            <div className="choose-file">
                                                <a href="/#"><i class="bi bi-upload"></i></a> <br />
                                                <a href="/#">Click to upload  </a>
                                                or{" "} drag and drop here <br />
                                                <span className="pt-0"> <b>XML</b> file only </span>
                                                <input
                                                    id={`file-${selectedFlowType.flowTypeId}`}
                                                    className="form-control form-control-lg"
                                                    type="file" accept=".xml"
                                                    onChange={(event) => {
                                                        setFieldValue("file", event.currentTarget.files[0]);
                                                        setFieldValue("flowTypeId", selectedFlowType.flowTypeId);
                                                        setFieldValue("name", selectedFlowType.name);
                                                        // resetting the event target value as some browsers 
                                                        // didnot clear the event target for input file
                                                        event.target.value = null;
                                                    }}
                                                    placeholder="Drop audio file here or Browse"
                                                />
                                            </div>
                                        </PmivrOverlayTrigger>
                                    </div>
                                    <div className="pmivr-upload-file py-3">
                                        <ErrorMessage name="file" component="div" className="field-error error" />
                                        <div className="file-name mb-2">File Path : <span className="file-path"> {values?.file?.name}</span></div>
                                    </div>
                                    <div>
                                        <button type="submit" title="Upload the selected flow file"
                                            className="pmivr-btn-app float-end mx-2" >
                                            Upload
                                        </button>
                                        <button title="Cancel" type="button"
                                            className="pmivr-btn-cancel float-end"
                                            onClick={closeDialog} >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            }
            footer={<></>}
        />
    )
});

UploadFileDialog.propTypes = {
    // function to upload the xml file
    uploadBaseFlow: PropTypes.func,
    // flow type (standard ivr, aaivr etc.)
    selectedFlowType: PropTypes.string
}

export default UploadFileDialog;