import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";

import { updateSystemVariablesList } from "../../../redux/actions/config.action";

import PmivrSnackbar from "../../../components/common/dialog/pmivr-snackbar";
import PmivrLabel from "../../../components/common/label/pmivr-label";
import PmivrTooltip from "../../../components/common/tooltip/pmivr-tooltip";

import ClientService from "../../../services/client.service";
import VariableService from '../../../services/variable.service';
import ConfigService from "../../../services/config.service";
import { TASK_ICONS } from "../../../constants/css-classes";

/**
 * Dnid values modal to configure variables based dnid 
 * @param {Object} props props passed to this component
 * @returns {React.Component} Html code to render 
 */
const DnidVariablesModal = (props) => {
    const { dnidRowInfo, updateDnidRowValues, dnidNumbersList, numberPool, closeAction, clientFlowExists, enableExt,
        dnidIndex
    } = props;
    const snackbarRef = useRef();
    const dispatch = useDispatch();
    /**
     * disableForm : To disable the form if edit not pressed
     * message: Any error while checking extension and text error to display and extStatus for having status for showing 
     * green tick and red cross
     * lext : Extension number for the flow
     */
    const [uiState, setUiState] = useState({
        disableForm: false,
        message: { text: '', isError: false, extStatus: "check" }, lext: dnidRowInfo.lext
    });
    // variables that we save against particular dnid
    const [dnidVariables, setDnidVariables] = useState([]);
    const [selectedNumberInfo, setSelectedNumberInfo] = useState();
    // list of variables names
    const [variablesName, setVariablesName] = useState([]);
    // transferChannelCode : For particular flow user can select a different transfer number and greater than 6
    const [transferChannelCode, setTransferChannelCode] = useState(dnidRowInfo?.transferChannelCode || "");
    // transferNumber on which call will be transfered in the flow.
    const [transferNumber, setTransferNumber] = useState(dnidRowInfo?.transferNumber || "");

    useEffect(() => {
        if (dnidRowInfo.filledVars) {
            setDnidVariables(dnidRowInfo.filledVars);
        }
        const numberInfo = numberPool.find((number) => number.phoneNumber === dnidRowInfo.dnid);
        setSelectedNumberInfo(numberInfo);
    }, [dnidRowInfo]);

    useEffect(() => {
        setAllVariables();
    }, []);

    /**
     * Get variables and set it in variables state.
     */
    const setAllVariables = async () => {
        if (!clientFlowExists) {
            const systemVariables = await ConfigService.getSysVariable();
            dispatch(updateSystemVariablesList({ systemVariables }));
        }
        const variablesInfo = await VariableService.getVariables();
        // names of all the variables
        const allVariablesNames = variablesInfo.map((varInfo) => varInfo.name);

        // populating the variables in state after getting the variable names.
        // Using timeout as getting all variable names is consuming time.
        setTimeout(() => {
            setVariablesName(allVariablesNames);
        }, 1000);
    }

    /**
     * Adds the new row inside the dialog form of dnid variable configuration
     */
    const handleAddRow = () => {
        if (dnidVariables.length < variablesName?.length) {
            setDnidVariables([{ variable: '', variableType: '', propertyName: '', value: '', collection: "" }, ...dnidVariables]);
        }
    };

    /**
     * Removes the row in the dialog form of configuring variables based dnid
     * @param {number} index index of the row to be deleted
     */
    const handleRemoveRow = (index) => {
        setDnidVariables(dnidVariables.filter((_, i) => i !== index));
    };

    /**
     * Handles the change in variables configuration for dnid
     * @param {number} index index of the row to update
     * @param {*} field field that needs to be updated
     * @param {*} value value with which we update the value for field
     */
    const handleChange = (index, field, value) => {
        // Create a deep copy of the dnidVariables array
        const newDnidRows = dnidVariables.map((row, i) => {
            if (i === index) {
                return {
                    ...row,
                    [field]: value
                };
            }
            return row;
        });
        setDnidVariables(newDnidRows);
    };

    /**
     * Save the dnid configuration values in thi dnid rows
     * @param {Object} e 
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (dnidVariables.length) {
            const variableNames = dnidVariables?.map(item => item.variable);
            const duplicateVariables = variableNames.filter((item, index) => variableNames.indexOf(item) !== index);
            if (duplicateVariables.length > 0) {
                setUiState({
                    ...uiState, disableForm: false,
                    message: { text: MESSAGES.DNID_INFO.SAME_VARIABLE_USED, isError: true }
                });
                return;
            }
        }

        const isValidExt = await checkExtValidity();
        if (isValidExt) {
            setUiState({ ...uiState, disableForm: true });
            try {
                const newDnidRowInfo = {
                    dnidRowValues: dnidVariables, newDnidValues: dnidRowInfo, lext: uiState.lext, dnidIndex,
                    transferChannelCode, transferNumber
                };
                updateDnidRowValues(newDnidRowInfo);
                setUiState({ disableForm: true, message: { text: MESSAGES.DNID_INFO.SAVED_SUCCESSFULLY, isError: false } });
                closeAction();
            } catch (err) {
                setUiState({ disableForm: false, message: { text: MESSAGES.SOMETHING_WENT_WRONG, isError: true } });
            }
        }
    };

    /**
     * Checks if the form is valid , has some errors to disable the submit button
     * @returns 
     */
    const isFormValid = () => {
        return dnidVariables.every(row => row.variable && row.variableType && row.value &&
            (row.variableType === 'single' || row.propertyName));
    };

    /**
     * Check if extension is valid or not
     * @returns {boolean} returns true false based on extension validity, extension nmber is valid or not
     */
    async function checkExtValidity() {
        try {
            if ((!clientFlowExists && uiState?.lext) || (clientFlowExists && enableExt)) {
                setUiState({ ...uiState, message: { text: "", isError: false } });
                const foundExtension = dnidNumbersList.find((dnidRow, index) => {
                    if (index !== dnidIndex) {
                        return (dnidRow.dnid === dnidRowInfo.dnid) && (dnidRow.lext === uiState.lext) && (index !== dnidRowInfo.index);
                    }
                });

                if (foundExtension) {
                    setUiState({
                        ...uiState, message: {
                            text: MESSAGES.DNID_INFO.ALREADY_CONFIGURED, isError: true,
                            extStatus: "invalid"
                        }
                    });
                    return false;
                }
                // check for validation if they lie within range 
                if (!typeof (Number(uiState.lext)) === 'number' ||
                    !(Number(uiState.lext) >= selectedNumberInfo?.rangeStart && Number(uiState.lext) <= selectedNumberInfo?.rangeEnd)
                ) {
                    setUiState({
                        ...uiState, message: {
                            text: MESSAGES.ENTER_VALID_EXTENSION + ` ${selectedNumberInfo?.rangeStart} to ${selectedNumberInfo?.rangeEnd}`,
                            isError: true, extStatus: "invalid"
                        }
                    });
                    return false;
                } else {
                    // verify from DB, check the existing ext value here
                    const dnidConfigResponse = await ClientService.getDnidConfig({ dnid: dnidRowInfo.dnid, lext: uiState.lext });
                    const existingExt = (dnidConfigResponse?.length) ? dnidConfigResponse[0] : null;
                    if (existingExt && Object.keys(existingExt).length) {
                        setUiState({
                            ...uiState, message: {
                                text: MESSAGES.EXTENSION_ALREADY_EXISTS, isError: true, extStatus: "invalid"
                            }
                        });
                        return false;
                    } else {
                        setUiState({ ...uiState, message: { text: "", isError: false, extStatus: "valid" } });
                        return true;
                    }
                }
            } else {
                setUiState({ ...uiState, message: { text: "", isError: false, extStatus: "valid" } });
                return true;
            }
        } catch (err) {
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.ERROR_EXTENSION);
                setUiState({ ...uiState, message: { text: MESSAGES.ERROR_EXTENSION, isError: true, extStatus: "invalid" } });
                return false;
            }
        }
    };

    /**
     * handle extension 
     * @param {string} extValue value of change in extension
     */
    const handleExt = (extValue) => {
        setUiState({ ...uiState, lext: extValue, message: { text: "", isError: false, extStatus: "check" } });
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <div className="pmivr-card card-secondary pmivr-number-input">
                <div className={uiState.message.isError ? "field-error text-center" : "field-success text-center"}>{uiState.message?.text}</div>
                <form onSubmit={handleSubmit}>
                    <div className="pmivr-container">
                        <div className="wrapper p-3 pt-0">
                            <div className="row mb-2 remove-arrows">
                                <div className={`${dnidRowInfo?.showExt ? "col-md-2" : "col-md-4"}`}>
                                    <PmivrLabel label="DNID" tooltip={TOOLTIP.INFO.DNID} />
                                    <input type="text" name="dnid" value={dnidRowInfo.dnid} className="form-control pmivr-input" disabled />
                                </div>
                                <div className={`${dnidRowInfo?.showExt ? "col-md-2" : "col-md-4"}`}>
                                    <PmivrLabel label="Transfer Code" tooltip={TOOLTIP.INFO.TRANSFER_CODE} />
                                    <select
                                        id="transferChannelCode"
                                        className="pmivr-select"
                                        aria-label="Default Transfer code"
                                        value={transferChannelCode}
                                        onChange={(event) => { setTransferChannelCode(event.target.value); }}
                                    >
                                        <option value=""></option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="*">*</option>
                                    </select>
                                </div>
                                <div className={`${dnidRowInfo?.showExt ? "col-md-3" : "col-md-4"}`}>
                                    <PmivrLabel label="Transfer Number" tooltip={TOOLTIP.INFO.TRANSFER_NUMBER} />
                                    <input type="text" name="transferNumber" value={transferNumber}
                                        className="form-control pmivr-input" onChange={(event) => { setTransferNumber(event.target.value) }} />
                                </div>
                                <div className="col-md-3">
                                    {
                                        dnidRowInfo?.showExt &&
                                        <>
                                            <PmivrLabel label="LEXT" tooltip={TOOLTIP.INFO.LEXT} />
                                            <input type="text" name="lext" value={uiState.lext} className="form-control pmivr-input"
                                                onChange={(e) => handleExt(e.target.value)}
                                                disabled={clientFlowExists && !enableExt} />
                                        </>
                                    }
                                </div>
                                <div className="col-md-2 ext-btns">
                                    {dnidRowInfo?.showExt &&
                                        <div className="d-flex">
                                            <PmivrTooltip message={TOOLTIP.CHECK_EXT}>
                                                <button type="button" onClick={async () => await checkExtValidity()}
                                                    className="pmivr-btn-secondary mx-1 p-2 check-ext-btn"
                                                    disabled={!uiState.lext || (clientFlowExists && !enableExt)}>
                                                    Check Ext
                                                </button>
                                            </PmivrTooltip>
                                            {
                                                uiState.message.extStatus === "valid" &&
                                                <i class="bi bi-check-circle text-success fs-3 valid-ext-icon"></i>
                                            }
                                            {
                                                uiState.message.extStatus === "invalid" &&
                                                <i class="bi bi-x-circle text-danger fs-3 invalid-ext-icon"></i>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            {transferChannelCode &&
                                <div class="alert alert-warning mb-0 mt-1" role="alert">
                                    <i class="bi-exclamation-triangle-fill"></i> <strong>{MESSAGES.TRANSFER_CODE_INFO}</strong>
                                </div>
                            }
                            <table className="table pmivr-table header-fixed table-body-block border mt-2">
                                <thead>
                                    <tr>
                                        <th width="25%" className="text-center">Variable
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONIGURED_VARIABLE}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th width="15%" className="text-center">Variable Type
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONFIGURED_VARIABLE_TYPE}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th width="15%" className="text-center">Property Name
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONFIGURED_PROPERTY_NAME}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th width="25%" className="text-center">Collection
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONFIGURED_COLLECTION}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th width="10%" className="text-center">Value
                                            <PmivrTooltip message={TOOLTIP.INFO.DNID_CONFIGURED_INPUT_FIELD}>
                                                <i className={`${TASK_ICONS.DISPLAY_INFO} px-2`}></i>
                                            </PmivrTooltip>
                                        </th>
                                        <th width="10%" className="text-center">
                                            <PmivrTooltip message={TOOLTIP.INFO.ADD_DNID_VALUES}>
                                                <button type="button" onClick={handleAddRow}
                                                    className="pmivr-btn-secondary p-2"
                                                    disabled={uiState.disableForm}
                                                    style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    Add +
                                                </button>
                                            </PmivrTooltip>
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                            <div className="pmivr-scroll">
                                {dnidVariables?.map((row, index) => (
                                    <ul key={index} className="align-items-end dnid-variable-heading">
                                        <li className="dnid-variables" style={{ width: "25%" }}>
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                className='pmivr-variable-input'
                                                labelKey="selectedVariableInField"
                                                onChange={(selected) => {
                                                    const value = selected.length && selected[0].customOption ? selected[0].selectedVariableInField : selected[0];
                                                    handleChange(index, 'variable', value);
                                                }}
                                                options={variablesName}
                                                onInputChange={(text, event) => handleChange(index, 'variable', text)}
                                                placeholder="Variable"
                                                multiple={false}
                                                selected={row.variable ? [row.variable] : []}
                                                allowNew
                                            />
                                        </li>
                                        <li className="dnid-variables" style={{ width: "15%" }}>
                                            <select className="pmivr-select"
                                                name="variableType"
                                                value={row.variableType}
                                                onChange={(e) => { handleChange(index, 'variableType', e.target.value) }}>
                                                <option value='' disabled hidden>Select</option>
                                                <option value='single'>Single</option>
                                                <option value='complex'>Complex</option>
                                            </select>
                                        </li>
                                        <li className="dnid-variables" style={{ width: "15%" }}>
                                            <input type="text" name={`propertyName-${index}`} value={row.propertyName}
                                                onChange={(e) => handleChange(index, 'propertyName', e.target.value)} className="form-control pmivr-input"
                                                disabled={row.variableType !== 'complex'}
                                                placeholder="Property Name" />
                                        </li>
                                        <li className="dnid-variables" style={{ width: "25%" }}>
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                className='pmivr-variable-input'
                                                labelKey="selectedVariableInField"
                                                disabled={row.variableType !== 'complex'}
                                                onChange={(selected) => {
                                                    const value = selected.length && selected[0].customOption ? selected[0].selectedVariableInField : selected[0];
                                                    handleChange(index, 'collection', value);
                                                }}
                                                onInputChange={(text, event) => handleChange(index, 'collection', text)}
                                                options={variablesName}
                                                placeholder="Collection"
                                                multiple={false}
                                                selected={row.collection ? [row?.collection] : []}
                                                allowNew
                                            />
                                        </li>
                                        <li className="dnid-variables" style={{ width: "10%" }}>
                                            <input type="text" name={`value-${index}`} value={row.value}
                                                onChange={(e) => handleChange(index, 'value', e.target.value)}
                                                className="form-control pmivr-input"
                                                placeholder="Value" />
                                        </li>
                                        <li className="dnid-variables" style={{ width: "10%" }}>
                                            <button type="button" disabled={uiState.disableForm} onClick={() => handleRemoveRow(index)} className="pmivr-btn-secondary">
                                                <i className="bi bi-x-lg"></i>
                                            </button>
                                        </li>
                                    </ul>
                                ))}
                            </div>
                            <div className="d-flex justify-content-end mt-4">
                                <PmivrTooltip message={TOOLTIP.CANCEL}>
                                    <button type="button" onClick={() => closeAction()}
                                        className="pmivr-btn-secondary mx-1 p-2">
                                        Cancel
                                    </button>
                                </PmivrTooltip>
                                <button className={`d-flex justify-contect-end pmivr-btn-app p-2`}
                                    disabled={uiState.disableForm || !isFormValid() || (dnidRowInfo.showExt && !uiState.lext)
                                        || (uiState.message.extStatus !== "valid" && enableExt)
                                    }
                                    type="submit" >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

DnidVariablesModal.propTypes = {
    // info of the selected dnid row
    dnidRowInfo: PropTypes.shape({
        dnid: PropTypes.string.isRequired,
        lext: PropTypes.string.isRequired,
    }).isRequired,
    // function to close the dialog
    closeAction: PropTypes.func,
    // function to update the predefined values
    updateDnidRowValues: PropTypes.func,
    // array of dnid rows that has info of pre defined values and dnid and lext
    dnidNumbersList: PropTypes.array,
    // number pool containing info of range start and range end
    numberPool: PropTypes.array,
    // flag to check if client flow exists that is we are editing the flow
    clientFlowExists: PropTypes.bool,
    // flag to to check if we show input field of ext
    enableExt: PropTypes.bool,
    // index of the selected dnid modal
    dnidIndex: PropTypes.string
};

export default DnidVariablesModal;