import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { FLOW_TYPE_ID } from "../../constants/flow";
import { MESSAGES } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";

import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import TemplateHistory from "./template-history";
import UploadFileDialog from "./components/upload-file-dialog";
import FlowTypeSettings from "./components/flow-type-settings";

import FlowService from "../../services/flow.service";
import UserService from "../../services/user.service";

/**
 * Displays the different flow types and provides option to upload the template flow for the flow type
 * @returns {React.Component} Html element to render
 */
const Templates = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // toggle the visibility of upload file dialog box
    const uploadFileDialogRef = useRef();
    // flowTypes to be displayed on UI
    const [flowTypes, setFlowTypes] = useState([]);
    // selected flow type
    const [selectedFlowType, setSelectedFlowType] = useState();
    /**
     * state for popups
     * showHistoryDialog: show or hide history dialog
     * disabled: disable the upload button in upload popup
     * showEditFlowTypeDialog: show or hide the edit flow type dialog box 
     */
    const [uiState, setUiState] = useState({ showHistoryDialog: false, disabled: false, showEditFlowTypeDialog: false });

    useEffect(() => {
        const init = async () => {
            try {
                const response = await FlowService.getFlowTypesInfo();
                // filtering the flow types array as custom flow, need not be shown 
                const flowTypes = response?.data?.filter(type => type.flowTypeId !== FLOW_TYPE_ID.CUSTOM_FLOW_ID);
                setFlowTypes(flowTypes);
            } catch (err) {
                // opening the snackbar
                if (snackbarRef?.current) {
                    snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
                }
            }
        }
        init()
    }, [selectedFlowType]);

    /**
     * Uploading the flow xml for the flow type
     * @param {{ file, flowTypeId, comments, name }} values file to be uploaded for a given flow type
     * @param {{ resetForm, setErrors }} methods formik render methods 
     */
    const uploadBaseFlow = async (values, { resetForm, setErrors }) => {
        setUiState({ ...uiState, disabled: true });
        setErrors({});
        try {
            const { file, flowTypeId, name, comments } = values;
            const formData = new FormData();
            formData.append("file", file);
            formData.append("flowTypeId", flowTypeId);
            formData.append("comments", comments);
            formData.append("mimetype", "text/xml");
            await FlowService.uploadBaseFlow(formData);
            setUiState({ ...uiState, disabled: false });
            // closing the dialog box
            uploadFileDialogRef.current.close();
            // opening the snackbar
            snackbarRef.current.open(`${MESSAGES.FILE_UPLOAD_SUCCESS} for ${name}`);
            resetForm();
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Updates the flow type details in db such as api url etc.
     * @param {{apiUrl, apiType, flowDetailApiUrl, flowDetailApiType}} values - values of fields in the edit flow type form
     */
    const updateFlowType = async (values) => {
        try {
            const flowTypeInfo = { ...values, flowTypeId: selectedFlowType.flowTypeId };
            const response = await FlowService.updateFlowType(flowTypeInfo);
            // update the selected flow type to update the details in popup without reloading
            setSelectedFlowType(response?.data);
            snackbarRef.current.open(response?.msg);
        } catch (error) {
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <PmivrDialog showDialog={uiState.showHistoryDialog}
                closeDialog={() => {
                    setUiState({ ...uiState, showHistoryDialog: false });
                    // opening the snackbar
                    snackbarRef.current.close();
                }}
                title={`Template History (Flow Type: ${selectedFlowType?.name})`}
                message={<TemplateHistory selectedFlowType={selectedFlowType} />}
                cssClass={'modal-width-medium'}
                footer={<button className="pmivr-btn-cancel"
                    onClick={() => {
                        setUiState({ ...uiState, showHistoryDialog: false });
                        // opening the snackbar
                        snackbarRef.current.close();
                    }}>
                    Cancel
                </button>} />
            <PmivrDialog showDialog={uiState.showEditFlowTypeDialog}
                closeDialog={() => setUiState({ ...uiState, showEditFlowTypeDialog: false })} title={"Flow Type Settings"}
                message={<FlowTypeSettings closeAction={() => setUiState({ ...uiState, showEditFlowTypeDialog: false })}
                    flowTypeInfo={selectedFlowType} updateFlowType={updateFlowType} />}
                footer={<></>} />
            <UploadFileDialog ref={uploadFileDialogRef}
                uploadBaseFlow={uploadBaseFlow}
                selectedFlowType={selectedFlowType} />
            <div className="row pt-1">
                <div className="row border-bottom  pb-3 pt-3 ">
                    <div className="col-lg-6">
                        <div className="px-3 pmivr-breadcrumb-list">
                            <Link to={APP_PAGES.HOME}>Home</Link> /
                            <Link to={APP_PAGES.SETTINGS}>Settings</Link> / Templates
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-1">
                <div className="row border-bottom  pb-3 pt-3 ">
                    <div className="col-lg-6">
                        <div className="px-3 pmivr-breadcrumb-list ">
                            <h4> <Link to={APP_PAGES.SETTINGS}><i class="bi bi-arrow-left arrow-left"></i></Link>  Admin</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                {
                    [...flowTypes?.values()]?.map((flowType, index) => {
                        return (
                            <div className="col-md-6" key={index}>
                                <div className="pmivr-card card card-effect mb-3 ms-4 p-3 py-4 me-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h6>{flowType.name}</h6>

                                        {UserService.hasPermission() && (
                                            <>
                                                <div className=" d-flex justify-content-end">
                                                    <button type="button" title="Check Template Version History" className="me-2 pmivr-btn-secondary"
                                                        disabled={flowType?.apiUrl}
                                                        onClick={() => {
                                                            setUiState({ ...uiState, showHistoryDialog: true });
                                                            // closing the snackbar
                                                            snackbarRef.current.close();
                                                            setSelectedFlowType(flowType);
                                                        }}>
                                                        <i class="bi bi-clock-history"></i> History
                                                    </button>
                                                    {
                                                        flowType?.apiUrl &&
                                                        <button type="button" title="Edit Flow Type Details" className="me-2 pmivr-btn-app"
                                                            onClick={() => {
                                                                setUiState({ ...uiState, showEditFlowTypeDialog: true });
                                                                snackbarRef.current.close();
                                                                setSelectedFlowType(flowType);
                                                            }}>
                                                            Edit
                                                        </button>
                                                    }
                                                    <button type="button" title="Upload the selected flow file" className="pmivr-btn-app"
                                                        disabled={flowType?.apiUrl}
                                                        onClick={() => {
                                                            // opening the dialog box
                                                            uploadFileDialogRef.current.open();
                                                            // opening the snackbar
                                                            snackbarRef.current.close();
                                                            setSelectedFlowType(flowType);
                                                        }}>
                                                        Upload
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div >
        </>
    );
}

export default Templates;